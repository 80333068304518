/* You can add global styles to this file, and also import other style files */
.ng-select{padding-bottom:1.25em}
.ng-select.ng-select-disabled .ng-select-container:after{border-bottom-color:transparent;background-image:linear-gradient(to right, rgba(0,0,0,0.42) 0%, rgba(0,0,0,0.42) 33%, transparent 0%);background-size:4px 1px;background-repeat:repeat-x}
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value{color:rgba(0,0,0,0.38)}
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder{color:rgba(0,0,0,0.38)}
.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow,.ng-select.ng-select-disabled .ng-clear-wrapper{color:rgba(0,0,0,0.38)}
.ng-select.ng-select-focused .ng-select-container:after{border-color:#3f51b5;border-width:2px}
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after{border-color:#3f51b5;border-width:2px}
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder{transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);color:#3f51b5}
.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow{color:#3f51b5}
.ng-select .ng-has-value .ng-placeholder,.ng-select.ng-select-filtered .ng-select-container .ng-placeholder{display:initial}
.ng-select .ng-has-value .ng-placeholder,.ng-select.ng-select-opened .ng-placeholder{transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px)}
.ng-select .ng-select-container{color:rgba(0,0,0,0.87);align-items:baseline;min-height:51.5px}
.ng-select .ng-select-container:after{border-bottom:thin solid rgba(0,0,0,0.42);content:'';bottom:0;left:0;right:0;position:absolute;transition:border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2)}
.ng-select .ng-select-container.ng-appearance-outline{padding:0 .5em;min-height:60px}
.ng-select .ng-select-container.ng-appearance-outline:after{border:solid 1px rgba(0,0,0,0.12);border-radius:5px;height:calc(100% - .5em);pointer-events:none;transition:border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)}
.ng-select .ng-select-container.ng-appearance-outline:hover:after{border-color:rgba(0,0,0,0.87);border-width:2px}
.ng-select .ng-select-container.ng-appearance-outline .ng-placeholder{padding:0 .25em;background-color:#fff;z-index:1}
.ng-select .ng-select-container.ng-appearance-outline .ng-value{padding-left:.25em}
.ng-select .ng-select-container .ng-value-container{align-items:stretch;padding:.4375em 0;border-top:.84375em solid transparent}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{position:absolute;color:rgba(0,0,0,0.54);transform-origin:left 0;transition:transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container .ng-placeholder{transform-origin:right 0}
.ng-select .ng-select-container .ng-value-container .ng-input{bottom:.4375em}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper{bottom:17px}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper{bottom:14px}
.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper{align-self:flex-end;bottom:9px}
.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper{align-self:flex-end;bottom:7px}
.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value{background-color:rgba(0,0,0,0.12);color:rgba(0,0,0,0.26)}
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:hover:after{background-image:none;border:dotted 1px rgba(0,0,0,0.12)}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-arrow-wrapper,.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-clear-wrapper{border-top:none}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-arrow-wrapper{top:0}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-clear-wrapper{top:4px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{background-color:#3f51b5;border-radius:2px;color:#fff;padding:2px 5px;margin:0 0.4375em 0.4375em 0}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin:0 0 0.4375em 0.4375em}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:rgba(0,0,0,0.12);color:rgba(0,0,0,0.26)}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{font-size:14px;font-weight:500}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{color:rgba(255,255,255,0.54);padding-right:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{padding-left:5px;padding-right:0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{color:rgba(255,255,255,0.87)}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{line-height:1.375em}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value{align-items:center}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container{padding-bottom:0;padding-top:.1875em}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper{border-top:.84375em solid transparent}
.ng-select .ng-clear-wrapper{color:rgba(0,0,0,0.54)}
.ng-select .ng-clear-wrapper:hover{color:rgba(0,0,0,0.87)}
.ng-select .ng-arrow-wrapper{bottom:2px}
.ng-select .ng-arrow-wrapper .ng-arrow{border-left:5px solid transparent;border-right:5px solid transparent;border-top:5px solid;margin:0 4px;color:rgba(0,0,0,0.54)}
.ng-select .ng-spinner-zone{top:3px}
.ng-dropdown-panel{background:#fff;left:0}
.ng-dropdown-panel.ng-select-bottom{top:calc(100% - 1.25em);box-shadow:0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12)}
.ng-dropdown-panel.ng-select-top{bottom:calc(100% - .84375em);box-shadow:0 -5px 5px -3px rgba(0,0,0,0.2),0 -8px 10px 1px rgba(0,0,0,0.14),0 -3px 14px 2px rgba(0,0,0,0.12)}
.ng-dropdown-panel.multiple .ng-option.selected{background:#fff}
.ng-dropdown-panel.multiple .ng-option.marked{background:rgba(0,0,0,0.04)}
.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid rgba(0,0,0,0.12);padding:0 16px;line-height:3em;min-height:3em}
.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid rgba(0,0,0,0.12);padding:0 16px;line-height:3em;min-height:3em}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;user-select:none;cursor:pointer;line-height:3em;height:3em;padding:0 16px;color:rgba(0,0,0,0.54);font-weight:500}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background:rgba(0,0,0,0.04)}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected{background:rgba(0,0,0,0.12);color:#3f51b5}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{line-height:3em;min-height:3em;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding:0 16px;text-decoration:none;position:relative;color:rgba(0,0,0,0.87);text-align:left}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{text-align:right}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background:rgba(0,0,0,0.04);color:rgba(0,0,0,0.87)}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{background:rgba(0,0,0,0.12);color:#3f51b5}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:rgba(0,0,0,0.38)}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:32px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:32px;padding-left:0}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-right:5px;font-size:80%;font-weight:400;color:rgba(0,0,0,0.38)}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}
::ng-deep .snack_error {
  background-color: #fc3434;
  color: #ffffff;
}
::ng-deep .snack_success {
  background-color: #00cc00;
  color: #ffffff;
}
.invalid {
  color: #fc3434;
}
.login-logo {
  width: 50%;
}
.filter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-section .filter-item,
.filter-section .searchbar {
  flex: 1;
  padding: 0 8px;
}
.filter-section .search .search-box {
  width: 100%;
}
.fa-passwd-reset > .fa-lock,
.fa-passwd-reset > .fa-key {
  font-size: 0.85rem;
}
.action-link {
  display: flex;
  align-items: center;
}
.action-link .eye {
  display: flex;
}
.loader {
  position: relative;
  left: calc(50% - 50px);
  top: 50%;
}
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: 999999;
}
.no-record {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 420px);
  justify-content: center;
}
.example-container {
  height: calc(100vh - 232px);
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.disabled {
  color: gray;
  pointer-events: none;
  cursor: not-allowed;
}
.left-header-actions .mat-form-field-infix {
  border-top-width: 0px;
}
.right-header-actions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
}
.content-wrapper {
  height: 100%;
}
@media (min-width: 960px) {
  .content-wrapper {
    height: calc(100vh - 150px);
  }
}
.content-wrapper__left, .content-wrapper__right {
  margin-top: 5px;
  height: 100%;
  overflow: auto;
}
.might-overflow {
  max-width: 200px;
}
.might-overflow p, .might-overflow {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.might-overflow-popup {
  max-width: 300px;
}
.might-overflow-popup p, .might-overflow-popup {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.might-overflow-span {
  max-width: 200px;
}
.might-overflow-span span, .might-overflow-span {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mat-tooltip {
  max-width: 500px !important;
  font-size: 12px !important;
}
.only-date-filter {
  width: 25%;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.modal-header h2 {
  margin-bottom: 0;
}
.td-ht-sm td.mat-cell,
.td-ht-sm td.mat-footer-cell,
.td-ht-sm th.mat-header-cell {
  height: 25px;
}
.td-ht-sm .ng-star-inserted {
  height: 25px;
}
.td-ht-sm .ng-star-inserted.td-lg {
  height: auto;
}
.td-ht-sm .ng-star-inserted.td-lg table tbody tr:last-child td {
  border-bottom: 0;
}
input.detector-info,
.detector-disp {
  font-family: Tahoma;
  font-size: 16px;
}
.detector-disp {
  display: flex;
}
.detector-disp .empty-bx {
  border: 1px solid #1e88e5;
  border-top: none;
  height: 4px;
  margin-bottom: 5px;
  margin-top: 1px;
}
.detector-disp__batch, .detector-disp__slot, .detector-disp__detector, .detector-disp__type {
  font-size: 10px;
  text-align: center;
}
.detector-disp__batch {
  width: 61px;
  margin-right: 5px;
}
.detector-disp__slot {
  width: 18px;
  margin-right: 4px;
}
.detector-disp__detector {
  width: 49px;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.inline-table-row {
  display: inline-table;
  width: 100%;
}
.full-width {
  margin-left: 240px;
}
@media (max-width: 1022px) {
  .full-width {
    margin-left: 0;
  }
}
.overflow-ellipsis {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 1px;
}
.no-data img {
  height: 80px;
  width: 80px;
}
.no-data p {
  font-size: 14px;
  margin: 0;
  color: #b0b7c3;
}